import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import styles from './contact.module.css'
import PageLinks from '../components/page-links'
import SEO from '../components/seo'

const Link = ({ title, descriptionHtml, callToAction, linkURL }) => (
  <div
    className={`${styles.linkWrapper} flex flex-col text-center md:text-left md:flex-row md:items-center`}
  >
    <div className="flex-1 md:pr-20">
      <h2 className={styles.linkTitle}>{title}</h2>
      <div
        className={styles.linkDescription}
        dangerouslySetInnerHTML={{ __html: descriptionHtml }}
      ></div>
    </div>
    <a
      className="inline-block flex-initial btn-primary mt-10 md:mt-0"
      href={linkURL}
    >
      {callToAction}
    </a>
  </div>
)

const ContactLinks = ({ links }) => (
  <div className={styles.contactLinksContainer}>
    {links.map((l, i) => (
      <Link key={i} {...l} />
    ))}
  </div>
)

const EmailUs = ({ children }) => (
  <div className={`${styles.emailUs} text-center w-full mt-8 md:w-4/5`}>
    {children}
  </div>
)

const FeedbackSection = ({ feedbackSection }) => (
  <div className={`${styles.feedbackWrapper} py-24`}>
    <Link {...feedbackSection} />
  </div>
)

export const ContactTemplate = ({ page }) => {
  const {
    title,
    contactLinks,
    emailUs,
    feedbackSection,
    pageLinks,
  } = page.frontmatter

  return (
    <>
    <SEO 
      title={"Contact The Projector | Find the Projector Cinema | Feedback"}
      description={"Can't find our cinema locations? Got burning questions you need answered? Have feedback for us? Want to get in touch? Let us point you in the right direction."}
    />
    <div className="container py-5 lg:py-20">
      <div className="mx-auto flex flex-col items-center w-full md:w-4/5">
        <h1 className={styles.title}>{title}</h1>
        <ContactLinks links={contactLinks} />
        <div
          className={`${styles.emailUs} text-center w-full mt-8 md:w-4/5`}
          dangerouslySetInnerHTML={{ __html: emailUs }}
        ></div>
        <FeedbackSection feedbackSection={feedbackSection} />
      </div>
      <div className="w-full mt-16">
        <PageLinks links={pageLinks} />
      </div>
    </div>
    </>
  )
}

const ContactPage = ({ data }) => {
  return <ContactTemplate {...data} />
}

ContactPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPage {
    page: markdownRemark(fields: { slug: { eq: "/contact/" } }) {
      frontmatter {
        title
        contactLinks {
          title
          descriptionHtml
          callToAction
          linkURL
        }
        emailUs
        feedbackSection {
          title
          descriptionHtml
          callToAction
          linkURL
        }
        ...PageLinks
      }
    }
  }
`
