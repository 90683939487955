import React from 'react'

import Link from '../components/link'
import forwardArrow from '../images/forward_arrow.png'
import styles from './page-links.module.css'

const PageLink = ({ link }) => (
  <div className={`${styles.pageLink}`}>
    <Link className="w-full no-underline" to={link.linkURL}>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-col justify-center">
          <h2 className={styles.linkSubtitle}>{link.subtitle}</h2>
          <h1 className={styles.linkTitle}>{link.title}</h1>
        </div>
        <img
          className={styles.linkArrow}
          src={forwardArrow}
          alt="forward-arrow"
        />
      </div>
    </Link>
  </div>
)

const PageLinks = ({ links }) => (
  <div
    className={`${styles.pageLinksContainer} w-full flex flex-col lg:flex-row text-left`}
  >
    {links.map((l, i) => (
      <PageLink link={l} key={i} />
    ))}
  </div>
)

export default PageLinks
